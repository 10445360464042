import {
  useEffect,
  useState,
} from 'react';

import moment from 'moment';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { LookupAction } from 'src/actions/lookup.action';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import io from 'socket.io-client';

import { AppAction } from '../../actions/app.action';

import MainSocket from 'src/components/MainSocket.js'
import { LstracuuAction } from 'src/actions/lstracuu.action';

const CustomerLookup9279 = (props) => {
  const dispatch = useDispatch()
  const { lichsu9279 } = useSelector(state => state.lookup)
  const { host, token, socket, socket_client_id } = MainSocket(useSelector(state => state));

  const today = new Date();

  const formatDate = (date, format) => {
    const map = {
      // mm: date.getMonth() + 1,
      // dd: date.getDate(),
      // yy: date.getFullYear().toString().slice(-2),
      // yyyy: date.getFullYear()

      m: date.getMonth() + 1,
      d: date.getDate(),
      Y: date.getFullYear()
    }

    // return format.replace(/mm|dd|yy|yyyy/gi, matched => map[matched])

    return format.replace(/m|d|Y/gi, matched => map[matched])
  }

  const [values, setValues] = useState({
    phone: '',
    startDate: formatDate(today, 'd/m/Y'),
    endDate: formatDate(today, 'd/m/Y'),
  });
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onLookup = () => {
    dispatch(AppAction.appLoading(true));
    var phone = '84' + (values.phone).substring((values.phone).length - 9);
    var startDate = values.startDate;
    var endDate = values.endDate;

    if (phone.trim() == '') {
      alert('Bạn phải nhập số điện thoại')
      dispatch(AppAction.appLoading(false));
      return;
    }

    if (startDate.trim() == '') {
      alert('Bạn phải nhập ngày bắt đầu')
      dispatch(AppAction.appLoading(false));
      return;
    }

    if (endDate.trim() == '') {
      alert('Bạn phải nhập ngày kết thúc')
      dispatch(AppAction.appLoading(false));
      return;
    }

    // console.log(phone, startDate, endDate)

    dispatch(LstracuuAction.create({ so_dien_thoai: phone, ten_tra_cuu: `Tra cứu lịch sử 9279`, noi_dung: `Tra cứu lịch sử 9279:\n{\nisdn: ${phone},\nstartDate: ${startDate},\nendDate: ${endDate}\n}` }));
    dispatch(LookupAction.lookup9279(phone, startDate, endDate))
  }

  useEffect(() => {
      dispatch(LookupAction.clearLookup())
      dispatch(AppAction.appLoading(false));
  }, [])

  return (
    <form {...props}>
      <Card>
        <CardHeader
          title="#6 TRA CỨU LỊCH SỬ 9279"
          // subheader="Không có giới hạn"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Số điện thoại"
            margin="normal"
            onChange={handleChange}
            required
            name='phone'
            value={values.phone}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Ngày bắt đầu"
            margin="normal"
            onChange={handleChange}
            required
            name='startDate'
            value={values.startDate}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Ngày kết thúc"
            margin="normal"
            onChange={handleChange}
            required
            name='endDate'
            value={values.endDate}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onLookup}
          >
            Tra cứu
          </Button>
        </Box>
        {lichsu9279 && typeof lichsu9279 !== 'undefined' && <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 2
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="40%">
                  Kết quả
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                hover
              >
                <TableCell width="40%">
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Thuê bao
                  </Typography>
                </TableCell>
                <TableCell>
                  {values.phone}
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>

              <TableRow
                hover
              >
                <TableCell>
                  Nội dung
                </TableCell>
                <TableCell>
                  Ngày ghi nhận
                </TableCell>
                <TableCell>
                  Người nhận
                </TableCell>
                <TableCell>
                  Người gửi
                </TableCell>
                <TableCell>
                  Loại
                </TableCell>
              </TableRow>

              {lichsu9279 && typeof lichsu9279 !== 'undefined' && lichsu9279 && (lichsu9279).map((res) => (
                <><TableRow
                  hover
                >
                  <TableCell>
                    {res.content}
                  </TableCell>
                  <TableCell>
                    {res.datetime}
                  </TableCell>
                  <TableCell>
                    {res.receiver}
                  </TableCell>
                  <TableCell>
                    {res.sender}
                  </TableCell>
                  <TableCell>
                    {res.type}
                  </TableCell>
                </TableRow></>
              ))}
            </TableBody>
          </Table>
        </Box>}
      </Card>
    </form>
  );
};

export default CustomerLookup9279;
