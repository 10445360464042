import { Helmet } from 'react-helmet';
import CustomerLookupDTCT
  from 'src/components/customer-lookup/CustomerLookupDTCT';
import CustomerLookupBalance
  from 'src/components/customer-lookup/CustomerLookupBalance';
import CustomerLookupInfo
  from 'src/components/customer-lookup/CustomerLookupInfo';
import CustomerLookupPackageHistory
  from 'src/components/customer-lookup/CustomerLookupPackageHistory';
import CustomerLookup9279
  from 'src/components/customer-lookup/CustomerLookup9279';

import {
  Box,
  Container,
} from '@material-ui/core';

const CustomerLookup = () => (
  <>
    <Helmet>
      <title>Tra cứu thông tin thuê bao</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container style={{maxWidth: "100%"}}>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupDTCT />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupBalance />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupInfo />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookupPackageHistory />
        </Box>
        <Box sx={{ pt: 3 }}>
          <CustomerLookup9279 />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerLookup;
