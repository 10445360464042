import { handleResponse } from '../utils/auth-header';
import axios, { axiosOption } from '../utils/axios';

export const LookupService = {
  lookupPackage: (phone) => {
    return axios.get(`/api/v1/lookup/package?phone=${phone}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  lookupInfo: (phone, type) => {
    return axios.get(`/api/v1/lookup/info?phone=${phone}&type=${type}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  lookupBalance: (phone) => {
    return axios.get(`/api/v1/lookup/balance?phone=${phone}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },

  lookup9279: (phone, startDate, endDate) => {
    return axios.get(`/api/v1/lookup/9279?phone=${phone}&startDate=${startDate}&endDate=${endDate}`, axiosOption()).then((rs) => {
      return handleResponse(rs)
    }).catch((error) => {
      throw handleResponse(error.response)
    })
  },
}